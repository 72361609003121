h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  font-family: "Muli";
  text-transform: uppercase;
  color: #495057;
  padding-top: 41px;
}

h1 {
  font-size: 3rem;
  line-height: 2.5rem;
  font-family: "Muli";
  color: #343a40;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 2rem;
}

.myP {
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  padding-top: 20px;
  color: #868e96;
}

.subhead {
  font-family: "Saira Extra Condensed", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.5rem;
}

.social-icons a {
  display: inline-block;
  height: 3.5rem;
  width: 3.5rem;
  background-color: #495057;
  color: #fff !important;
  border-radius: 100%;
  text-align: center;
  font-size: 1.5rem;
  line-height: 3.5rem;
  margin-right: 1rem;
}

.social-icons a:last-child {
  margin-right: 0;
}

.social-icons a:hover {
  background-color: #bd5d38;
}

.dev-icons {
  font-size: 3rem;
}

.dev-icons .list-inline-item i:hover {
  color: #bd5d38;
}

.bg-primary {
  background-color: #bd5d38 !important;
}

.mytext-primary {
  color: #bd5d38 !important;
}

.my_a {
  color: #bd5d38 !important;
}

my_a:hover,
my_a:focus,
my_a:active {
  color: #824027 !important;
}

/* section.resume-section {
  padding-bottom: 5rem !important;
  max-width: 75rem !important;
}

section.resume-section .resume-item .resume-date {
  min-width: none;
}

section.resume-section {
  padding-bottom: 5rem !important;
  max-width: 75rem !important;
}

section.resume-section .resume-item .resume-date {
  min-width: none;
} */

section.resume-section-thisApp {
  min-width: none;
  min-width: 18rem;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  padding: 100;
  color: #868e96;
}
